<template>
  <b-card class="my-5 no-border shadow" no-body>
    <b-list-group flush>
      <b-list-group-item>
        <div class="d-flex align-items-begin">
          <div>
            <h4> {{ post.title }} </h4>
          </div>
          <div class="ml-auto text-right">  
            by: {{ post.author.owner.email }}<br>
            {{ post.createdAt.slice(0, -14) }}
          </div>
        </div>
        <div>
          {{ post.body }}
        </div>
      </b-list-group-item>
      <b-list-group-item v-for="r in relatedPosts" :key="r._id" class="d-flex">
        <div class="ml-3 mb-1" >
          {{r.body}}
        </div>
        <div class="ml-auto text-right">
          {{r.author.owner.email}}<br>
         <b-link v-if="user.email==r.author.owner.email" @click="deleteReply(r._id)">Delete</b-link>
          
        </div>
      </b-list-group-item>
      <b-list-group-item>
        <b-form>
          <b-form-group>
            <b-textarea v-model="newPostData.body"></b-textarea>
          </b-form-group>
          <div class="d-flex">
          <b-button class="ml-auto" size="sm" @click="addReply">
            <b-icon-reply /> Reply
          </b-button>
          </div>
        </b-form>
      </b-list-group-item>
    </b-list-group>
  </b-card>
</template>

<script>
import Axios from "axios";
import { mapGetters } from "vuex";

export default {
  props: ["post"],
  data: function() {
    return {
      relatedPosts: [],
      newPostData: {
        title: `Re:${this.post.title}`,
        body: ""
      }
    };
  },
  computed: {
    ...mapGetters("user", {
      isLoggedIn: "getUserStatus",
      user: "getUser"
    })
  },
  methods: {
    async deleteReply(id){
      Axios
        .delete("/.netlify/functions/post/", {
          params: {_id: id}
        })
        .then( res=>{
          this.relatedPosts.forEach(e=>{
            if(e._id==res.data._id)
              e.body = "--"
          })
        })
        .catch(err=>{
          console.log(err)
        })

    },
    async addReply() {
      this.newPostData.previousPost = this.post._id;
      Axios.post("/.netlify/functions/post/", this.newPostData)
        .then(res => {
          this.relatedPosts.push(res.data)
          this.newPostData.body=""
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
  async created() {
    let res = await Axios.get("/.netlify/functions/post/", {
      params: { previousPost: this.post._id }
    })
    this.relatedPosts = res.data
  }
};
</script>
