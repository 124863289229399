<template>
  <b-container class="p-0" fluid='lg'>
    <div class="d-flex">
      <region-info v-if="region" :info="region.info"></region-info>
      <div v-if="isAdmin" class="ml-auto text-right">
        <b-button
          size="sm"
          class="mr-4"
          variant="outline-danger"
          @click="deleteRegion()"
          >Delete</b-button
        >
        <b-button size="sm" @click="initRegionModal()">
          Edit Region
          <b-icon
            v-if="isLoading"
            icon="arrow-clockwise"
            animation="spin"
          ></b-icon>
        </b-button>
        <br />
        ID: {{ user.db_token }}
        <span class="text-danger">{{ error }}</span>
        <br />
      </div>
    </div>
    <br />
    <b-tabs v-if="region">
      <b-tab>
        <template #title> <b-icon-chat /> Posts x {{ posts.length }}</template>
        <div class="d-flex">
          <b-button class="ml-auto my-2" size="sm" @click="initPostForm"
            >New Topic</b-button
          >
        </div>
        <post-info
          :post="post"
          v-for="post in posts"
          :key="post.id"
        ></post-info>
      </b-tab>
      <b-tab>
        <template #title>
          <b-icon-people></b-icon-people> Admin x {{ region.admin.length }} |
          Member x {{ region.member.length }}
        </template>
        admin:
        {{ this.region.admin }} <br />
        members:
        {{ this.region.member }}
      </b-tab>
    </b-tabs>

    <b-modal
      v-model="regionModal"
      centered
      button-size="sm"
      @ok="updateRegion"
      @cancle="clearRegionForm"
      @close="clearRegionForm"
    >
      <template #modal-title>Update Region</template>
      <b-form>
        <b-form-group label="city">
          <b-input v-model="regionFormData.city"></b-input>
        </b-form-group>
        <b-form-group label="state">
          <b-input v-model="regionFormData.state"></b-input>
        </b-form-group>
        <b-form-group label="description">
          <b-textarea v-model="regionFormData.description"></b-textarea>
        </b-form-group>
        <b-form-group label="tags">
          <b-form-tags v-model="regionFormData.tags"></b-form-tags>
        </b-form-group>
        <b-form-group>
          <b-checkbox v-model="regionFormData.isPublic">Public</b-checkbox>
        </b-form-group>
      </b-form>
    </b-modal>
    <b-modal
      v-model="postModal"
      centered
      button-size="sm"
      @ok="newPost"
      @cancle="clearPostForm"
      @close="clearPostForm"
    >
      <template #modal-title>New Topic</template>
      <b-form>
        <b-form-group label="title">
          <b-input v-model="postFormData.title"></b-input>
        </b-form-group>
        <b-form-group label="Content">
          <b-textarea v-model="postFormData.body"></b-textarea>
        </b-form-group>
      </b-form>
    </b-modal>
  </b-container>
</template>

<script>
const axios = require("axios");
import RegionInfo from "@/components/RegionInfo.vue";
import PostInfo from "@/components/PostInfo.vue";
import { mapGetters } from "vuex";
export default {
  components: { RegionInfo, PostInfo },
  data: function() {
    return {
      isLoading: false,
      error: "",
      region: {},
      regionModal: false,
      regionFormData: {},
      posts: [],
      postModal: false,
      postFormData: {}
    };
  },
  computed: {
    ...mapGetters("user", {
      isLoggedIn: "getUserStatus",
      user: "getUser"
    }),
    id: function() {
      return this.$route.params.id;
    },
    info: function() {
      return this.region.info;
    },
    isAdmin: function() {
      return this.region.admin.indexOf(this.user.db_token) != -1;
    }
  },
  methods: {
    initPostForm() {
      this.postFormData.title = "";
      this.postFormData.body = "";
      this.postFormData.region = this.region._id;
      this.postFormData.author = this.user.db_token;
      this.postModal = true;
    },
    clearPostForm() {
      this.postFormData.title = "";
      this.postFormData.body = "";
      this.postModal = false;
    },
    newPost() {
      this.isLoading = true;
      axios
        .post("/.netlify/functions/post/", this.postFormData)
        .then(res => {
          this.posts.push(res.data);
        })
        .catch(err => {
          this.error = err;
        })
        .finally(() => {
          this.isLoading = false;
          this.postModal = false;
        });
    },
    initRegionModal() {
      this.regionFormData.city = this.region.info.city;
      this.regionFormData.state = this.region.info.state;
      this.regionFormData.description = this.region.info.description;
      this.regionFormData.tags = this.region.tags;
      this.regionFormData.isPublic = this.region.isPublic;
      this.regionModal = true;
    },
    async deleteRegion() {
      console.log("delete region");
      await axios.delete("/.netlify/functions/region/", {
        params: {
          _id: this.region._id
        }
      });
      this.$router.go(-1);
    },
    updateRegion() {
      this.regionModal = false;
      this.isLoading = true;
      console.log("update region");
      this.region.info.city = this.regionFormData.city;
      this.region.info.state = this.regionFormData.state;
      this.region.info.description = this.regionFormData.description;
      this.region.tags = this.regionFormData.tags;
      this.region.isPublic = this.regionFormData.isPublic;
      axios
        .put("/.netlify/functions/region/", this.region)
        .then(res => {
          this.region = res.data;
        })
        .catch(err => {
          this.error = err;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    clearRegionForm() {
      this.regionFormData = {};
      this.regionModal = false;
    }
  },
  created: async function() {
    this.isLoading = true;
    axios
      .get(`/.netlify/functions/region/?_id=${this.id}`)
      .then(res => {
        this.region = res.data[0];
        return axios.get(`/.netlify/functions/post/?region=${this.id}`);
      })
      .then(res => {
        this.posts = res.data;
        console.log("post", res.data);
      })
      .catch(err => {
        this.error = err;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
};
</script>
