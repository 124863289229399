<template>
<div class="mx-2">
  <div >
    <h3 class="text-capitalize">
      <strong>
    {{info.city}}, {{info.state}} 
      </strong>
    </h3>
    <p>
      {{info.description}}
    </p>
  </div>
</div>
</template>

<script>
export default {
  props:['info'],
  data: function(){
    return {}
  },
  computed:{
  }
  
}
</script>